// KEEP COLORS IN SYNC WITH global.css
const colors = {
  pink: "#E83385",
  orange: "#FF9E33",
  purple: "#65219D",
  yellow: "#fade0f",
  grey: "#999999",
};

export default colors;
